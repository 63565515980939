class Winner {

    play = (player, dealer) => {

        /* Fields */
        const dealerLevel = parseInt(dealer.level)
        const playerLevel = parseInt(player.level)

        const dealerStrength = parseInt(dealer.strength)
        const playerStrength = parseInt(player.strength)

        const dealerCode = dealer.code
        const playerCode = player.code

        const BET_LOSE = "no"
        const BET_WIN = "yes"
        const BET_DRAW = "yes"

        /* If the dealer has no game */
        if (dealerLevel === 0) {
            return "yes"
        }

        /* If the dealer card is higher */
        if (dealerLevel > playerLevel) {
            return BET_LOSE
        }

        /* If the player card is higher */
        if (dealerLevel < playerLevel) {
            return BET_WIN
        }

        /* If the dealer's cards and the player's cards are the same */
        if (dealerLevel === playerLevel) {


            /* Cases where cards are a PAIR, ACEKING, THREEOFAKIND OR FOUROFAKIND */
            if (
                (dealerCode === "PAIR" && playerCode === "PAIR") || (dealerCode === "PAIR" && playerCode === "ACEKING") ||
                (dealerCode === "ACEKING" && playerCode === "PAIR") || (dealerCode === "ACEKING" && playerCode === "ACEKING") ||
                (dealerCode === "THREEOFAKIND" && playerCode === "THREEOFAKIND") || (dealerCode === "FOUROFAKIND" && playerCode === "FOUROFAKIND")
            ) {
                const dealerValue = parseInt(dealer.value)
                const playerValue = parseInt(player.value)

                if (dealerValue > playerValue) {
                    return BET_LOSE
                }
                if (dealerValue < playerValue) {
                    return BET_WIN
                }
                if (dealerValue === playerValue) {
                    if (dealerStrength > playerStrength) {
                        return BET_LOSE
                    }
                    if (dealerStrength < playerStrength) {
                        return BET_WIN
                    }
                    if (dealerStrength === playerStrength) {
                        return BET_DRAW
                    }
                }

            }

            /* Cases where cards are TWOPAIRS OR  FULLHOUSE */
            else if ((dealerCode === "TWOPAIRS" && playerCode === "TWOPAIRS") || (dealerCode === "FULLHOUSE" && playerCode === "FULLHOUSE")) {

                const dealerValue = parseInt(dealer.value)
                const playerValue = parseInt(player.value)

                if (dealerValue > playerValue) {
                    return BET_LOSE
                }
                if (dealerValue < playerValue) {
                    return BET_WIN
                }
                if (dealerValue === playerValue) {

                    const dealerAdditional = parseInt(dealer.additional)
                    const playerAdditional = parseInt(player.additional)

                    if (dealerAdditional > playerAdditional) {
                        return BET_LOSE
                    }
                    if (dealerAdditional < playerAdditional) {
                        return BET_WIN
                    }
                    if (dealerAdditional === playerAdditional) {
                        if (dealerStrength > playerStrength) {
                            return BET_LOSE
                        }
                        if (dealerStrength < playerStrength) {
                            return BET_WIN
                        }
                        if (dealerStrength === playerStrength) {
                            return BET_DRAW
                        }
                    }
                }
            }


            /* OHTHER CASES */
            else {
                if (dealerStrength > playerStrength) {
                    return BET_LOSE
                }
                if (dealerStrength < playerStrength) {
                    return BET_WIN
                }
                if (dealerStrength === playerStrength) {
                    return BET_DRAW
                }
            }

        }

    }

}


module.exports = Winner