import React, { Component } from "react"

/* Constants */
import { utils, closed } from '../constants'

/* Picture */
import { Card } from "../components"



/* Widget Dealer Cards */
class DealerCards extends Component {

    _result = () => {

        const { game, openGame } = this.props

        if (openGame && !game) {
            if (openGame.level > 0) {
                const text = `${utils.translate(openGame.name)}${openGame.hasAceKing ? ` + ${utils.translate('ACE KING')}` : ""}`
                let size = "default"

                if (text.length > 20) {
                    size = "smaller"
                }

                if (openGame.level > 0) {
                    return (<div className={`dealer-game-status green ${size}`}>{text}</div>)
                }
            }
        }

        if (game) {

            if (game.level === 0) {
                return <div className="dealer-game-status red">{utils.translate('NO GAME')}</div>
            }

            const text = `${utils.translate(game.name)}${game.hasAceKing ? ` + ${utils.translate('ACE KING')}` : ""}`
            let size = "default"

            if (text.length > 20) {
                size = "smaller"
            }

            if (game.level > 0) {
                return (<div className={`dealer-game-status green ${size}`}>{text}</div>)
            }

        }
    }

    openStatus = () => {

        const { ante, solution, open } = this.props

        const boxCount = utils.valueLength(ante)
        const index = ante.findIndex(e => e > 0)

        if (boxCount === 1 && open[index]) {
            return true
        }

        if (boxCount === 2) {

            if (open[0] && open[1]) {
                return true
            }

            if (open[0] && (solution[1] === "FOLD" || solution[1] === "BET")) {
                return true
            }

            if (open[1] && (solution[0] === "FOLD" || solution[0] === "BET")) {
                return true
            }
        }
    }

    render = () => {

        const { cards, game, openGame } = this.props
        let dealerCards = utils.getUniqueValues(cards, "uuid")
        const open = this.openStatus()

        return (
            <div className="dealer-container">

                <div className="dealer-container-header">
                    <div className="dealer-container-title">{utils.translate("DEALER'S HAND")}</div>
                    {this._result()}
                </div>

                <div className="dealer-cards">
                    {dealerCards.map((card, index) => {

                        const status = game || (open && index > 0) || index === 4 ? true : false

                        const i = game && game.data ? game.data.findIndex(e => parseInt(e.id) === parseInt(card.id) && e.status === true) : -1
                        const j = openGame && openGame.data ? openGame.data.findIndex(e => parseInt(e.id) === parseInt(card.id) && e.status === true) : -1

                        const key = index === 4 ? card.uuid : index

                        return (
                            <div className={`dealer-card ${(i > -1 || (open && j > -1)) ? 'dealer-card-animated' : 'dealer-card-hidden'}`} key={`d-${key}`}>
                                <div className={`dealer-flip-card  ${status ? 'open' : 'hidden'}`}>
                                    <div className="dealer-flip-card-inner">
                                        <div className="dealer-flip-card-front">
                                            <img src={closed.image} alt="Closed" />
                                        </div>
                                        <div className="dealer-flip-card-back">
                                            {status ? <Card data={card.name} /> : <img src={closed.image} alt="Closed" />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )

                    })}
                </div>

                <div className="dealer-spaces">
                    {[1, 2, 3, 4, 5].map(key =>
                        <div key={`${key}`} className="dealer-space">
                            <div className="dealer-space-circle">
                                <div className="dealer-space-diamond"></div>
                            </div>
                            <div className="dealer-space-round" />
                        </div>
                    )}
                </div>

            </div>
        )
    }

}

export default DealerCards