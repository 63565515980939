import React, { PureComponent } from "react"


/* PureComponent Bet */
class Bet extends PureComponent {

    render = () => {

        const { onClick, disabled } = this.props

        if (disabled) {
            return <div style={{ opacity: 0.3, boxShadow: 'none' }} className="game-button bet-button">BET</div>
        }

        return <div onClick={() => onClick()} className="game-button bet-button">BET</div>
    }

}

export default Bet