import React, { PureComponent } from "react"

/* Constants */
import { utils } from "../constants"


/* PureComponent Open */
class Open extends PureComponent {

    render = () => {

        const { onClick } = this.props

        return <div onClick={() => onClick()} className="game-button open-button">{utils.translate("OPEN 3 CARD")}</div>
    }

}

export default Open