/* SWAL */
import Swal from "sweetalert2"

/* Toast show finction */
import { toast } from 'react-toastify'

/* Translates */
import { data as translates } from '../translates'

/* Libraries for translate */
import { store } from '../store/index'

/* Fields */
const currencies = [
    {
        name: "Доллар",
        code: "USD",
        isAfter: 0,
        symbol: "$"
    },
    {
        name: "Евро",
        code: "EUR",
        isAfter: 0,
        symbol: "€"
    },
    {
        name: "Тенге",
        code: "KZT",
        isAfter: 1,
        symbol: "₸"
    },
    {
        name: "Рубль",
        code: "RUB",
        isAfter: 1,
        symbol: "₽"
    },
    {
        name: "Kyrgyz som",
        code: "KGS",
        isAfter: 1,
        symbol: "с."
    },
    {
        name: "Uzbek sum",
        code: "UZS",
        isAfter: 1,
        symbol: "сўм"
    },
    {
        name: "Украинская гривна",
        code: "UAH",
        isAfter: 1,
        symbol: "₴"
    },
    {
        name: "Индийская рупия",
        code: "INR",
        isAfter: 1,
        symbol: "₹"
    },
    {
        name: "Армянский драм",
        code: "AMD",
        isAfter: 1,
        symbol: "֏"
    },
    {
        name: "Канадский доллар",
        code: "CAD",
        isAfter: 1,
        symbol: "CA$"
    },
    {
        name: "Польский злотый",
        code: "PLN",
        isAfter: 1,
        symbol: "zł"
    },
    {
        name: "Египетский фунт",
        code: "EGP",
        isAfter: 1,
        symbol: "ج.م"
    },
    {
        name: "Датская крона",
        code: "DKK",
        isAfter: 1,
        symbol: "kr"
    },
    {
        name: "Румынский лей",
        code: "RON",
        isAfter: 1,
        symbol: "L"
    },
    {
        name: "Молдавский лей",
        code: "MDL",
        isAfter: 1,
        symbol: "L"
    },
    {
        name: "Болгарский Лев",
        code: "BGN",
        isAfter: 1,
        symbol: "лв"
    },
    {
        name: "Шведская крона",
        code: "SEK",
        isAfter: 1,
        symbol: "kr"
    },
    {
        name: "Норвежская крона",
        code: "NOK",
        isAfter: 1,
        symbol: "kr"
    },
    {
        name: "Турецкая лира",
        code: "TRY",
        isAfter: 1,
        symbol: "₺"
    },
    {
        name: "Китайский юань",
        code: "CNY",
        isAfter: 1,
        symbol: "¥"
    },
    {
        name: "Пакистанская рупия",
        code: "PKR",
        isAfter: 1,
        symbol: "Rs"
    },
    {
        name: "Туркменский манат",
        code: "TMT",
        isAfter: 1,
        symbol: "T"
    },
    {
        name: "Таджикский сомони",
        code: "TJS",
        isAfter: 1,
        symbol: "SM"
    },
    {
        name: "Азербайджанский манат",
        code: "AZN",
        isAfter: 1,
        symbol: "₼"
    },
    {
        name: "Грузинский лари",
        code: "GEL",
        isAfter: 1,
        symbol: "₾"
    }
]


/* Check Value */
export const check = (value, defaultValue = "") => {

    /* Check the price for the void */
    if (value === null || value === undefined)
        return defaultValue

    return value
}

/* Determine Value Length */
export const valueLength = data => {

    let count = 0

    if (data && Array.isArray(data)) {
        data.forEach(item => {
            if (item) {
                count = count + 1
            }
        })
    }

    return count
}


/* PRICE CONVERTOR */
export const convertor = price => {

    /* Check the price for the void */
    if (price === null || price === undefined)
        return ""

    return "$\xa0" + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
}

export const getWithCurrency = (number, currency, symbol = "\xa0") => {

    const space = '\xa0'
    /* Check the price for the void */
    if (number === null || number === undefined)
        return ""

    const balance = parseFloat(number)

    let final = Number.isInteger(balance) ? balance.toFixed(0) : balance.toFixed(2)

    if (currency.toLowerCase() === 'uzs') {
        final = counter(final)
    }

    const index = currencies.findIndex(c => c.code.toLowerCase() === currency.toLowerCase())

    let beforeSymbol = ''
    let afterSymbol = ''

    if (index > -1) {
        if (currencies[index].isAfter === 1) {
            afterSymbol = space + currencies[index].symbol
        }
        if (currencies[index].isAfter === 0) {
            beforeSymbol = currencies[index].symbol + space
        }
    }
    else {
        beforeSymbol = "$" + space
    }

    /* Return price with spaces and currency */
    return beforeSymbol + final.toString().replace(/\B(?=(\d{3})+(?!\d))/g, symbol) + afterSymbol
}

/* Counter */
export const counter = (value, fixed = 1) => {

    let count = value

    if (parseInt(value) >= 1000) {
        if (parseInt(value) % 1000 === 0) {
            const calculate = Math.round(parseInt(value) / 1000)
            count = `${calculate}K`
        }
        else if (parseInt(value) % 100 === 0) {
            const calculate = (parseInt(value) / 1000).toFixed(fixed)
            count = `${calculate}K`
        }
    }

    if (parseInt(value) >= 1000000) {
        const calculate = (parseInt(value) / 1000000).toFixed(fixed)
        count = `${calculate}M`
    }

    return count
}

/* get unique values from array */
export const getUniqueValues = (arr, prop) => {
    return arr.filter((obj, index, self) =>
        index === self.findIndex((o) =>
            o[prop] === obj[prop]
        )
    )
}

/* Swal fire function */
export const toggleError2 = (text, icon = "error", background = "#ffcd05", color = "#000", width = 300, timer = 3000) => {

    const Toast = Swal.mixin({
        animation: false,
        width,
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
        background,
        color,
        target: document.getElementById("sweetforsweet"),
        didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer
            toast.onmouseleave = Swal.resumeTimer
        }
    })

    Toast.fire({ icon, title: text })
}

/* Toasts */
export const toggleError = (text, icon = "error", toastId, handleToastClose) => {

    const button = ({ closeToast }) => (<button onClick={() => handleToastClose(toastId)} className="Toastify__close-button Toastify__close-button--dark" type="button" aria-label="close"><svg aria-hidden="true" viewBox="0 0 14 16"><path fillRule="evenodd" d="M7.71 8.23l3.75 3.75-1.48 1.48-3.75-3.75-3.75 3.75L1 11.98l3.75-3.75L1 4.48 2.48 3l3.75 3.75L9.98 3l1.48 1.48-3.75 3.75z"></path></svg></button>)

    let data = {
        position: "top-center",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
    }

    if (toastId) {
        data.toastId = toastId
    }

    if (icon === "warning" || icon === "success") {
        data.autoClose = 5000
    }

    if (toastId === "internet") {
        data.autoClose = false
        data.closeButton = button
    }

    if (icon === "info") {
        toast.info(text, data)
    }

    if (icon === "success") {
        toast.success(text, data)
    }

    if (icon === "warning") {
        toast.warn(text, data)
    }

    if (icon === 'error') {
        toast.error(text, data)
    }

}

export const toastDismiss = (id) => {
    toast.dismiss({ id: id })
}


export const getChips = data => {

    let chips = []

    if (data && Array.isArray(data) && data.length === 6) {
        data.forEach(item => {
            if (item) {
                const list = item.split("-")
                if (list && Array.isArray(list) && list.length > 1) {
                    chips.push({ value: parseInt(list[0]), color: list[1] })
                }
            }
        })
    }

    return chips
}




/* Translate */
export const translate = value => {

    const state = store.getState()
    const language = state.language
    const locale = language ? language : "ru"

    let text = value

    if (typeof value === "string") {
        if (`${locale}` in translates) {
            const data = translates[`${locale}`]
            if (`${value}` in data) {
                text = data[`${value}`]
            }
        }
    }

    return text
}