const data = {

    /* Preloader */
    "Supported by": "Supported by",

    /* App */
    "Transaction error": "Transaction error",
    "You must select 2 or more cards": "You must select 2 or more cards",
    "The game ended due to the administrator's initiative": "The game ended due to the administrator's initiative",

    /* Error */
    "Reload": "Reload",
    "Error!": "Error!",
    "Your session has expired due to inactivity!": "Your session has expired due to inactivity!",
    "To start the game again, please refresh the page.": "To start the game again, please refresh the page.",
    "You are logged out of the game!": "You are logged out of the game!",
    "To continue, please refresh the page.": "To continue, please refresh the page.",
    "You have entered the game from a new tab.": "You have entered the game from a new tab.",
    "Something went wrong! Try again later or refresh the page.": "Something went wrong! Try again later or refresh the page.",

    /* GAME */
    "Payout": "Payout",
    "NO GAME": "NO GAME",
    "ACE KING": "ACE KING",
    "ROYAL FLUSH": "ROYAL FLUSH",
    "STRAIGHT FLUSH": "STRAIGHT FLUSH",
    "FOUR OF A KIND": "FOUR OF A KIND",
    "FULL HOUSE": "FULL HOUSE",
    "FLUSH": "FLUSH",
    "STRAIGHT": "STRAIGHT",
    "THREE OF A KIND": "THREE OF A KIND",
    "TWO PAIRS": "TWO PAIRS",
    "PAIR": "PAIR",
    "HIGH CARD": "HIGH CARD",

    /* Balance */
    "BALANCE:": "BALANCE:",
    "LAST WIN:": "LAST WIN:",
    "TOTAL:": "TOTAL:",
    "Cashier": "Cashier",

    /* Chips */
    "Clear": "Clear",
    "Cancel": "Cancel",
    "Double": "Double",
    "Repeat": "Repeat",
    "You don't have enough funds": "You don't have enough funds",
    "Maximum bid": "Maximum bid",
    "Maximum bid for bonus": "Maximum bid for bonus",
    "Insurance won": "Insurance won",
    "Insurance lost": "Insurance lost",

    /* Dealer Cards */
    "DEALER'S HAND": "DEALER'S HAND",

    /* History */
    "History": "History",
    "Game": "Game",
    "Balance before the game:": "Balance before the game:",
    "Balance after the game:": "Balance after the game:",
    "Load more": "Load more",
    "Today": "Today",
    "Yesterday": "Yesterday",
    "Dealer": "Dealer",
    "Player": "Player",
    "Type": "Type",
    "Amount": "Amount",
    "Paid": "Paid",
    "Total": "Total",
    "ANTE": "ANTE",

    "ANTE + BONUS": "ANTE + BONUS",
    "ANTE FOR TWO HANDS": "ANTE FOR TWO HANDS",
    "ANTE + BONUS FOR TWO HANDS": "ANTE + BONUS FOR TWO HANDS",

    "BONUS": "BONUS",
    "BET": "BET",
    "INSURANCE": "INSURANCE",
    "PURCHASE OF A GAME FOR A DEALER": "PURCHASE OF A GAME FOR A DEALER",
    "6-CARD": "6-CARD",
    "EXCHANGE": "EXCHANGE",
    "FOLD": "FOLD",
    "WIN": "WIN",
    "OPEN": "OPEN",
    "HAND": "HAND",
    "Refunds": "Refunds",
    "Replenishment of the amount": "Replenishment of the amount",
    "Return of funds": "Return of funds",

    /* Insurance */
    "Select insurance chip": "Select insurance chip",
    "Close": "Close",

    /* Payout */
    "Bonus": "Bonus",
    "Maximum payout": "Maximum payout",

    /* Player Cards */
    "YOUR HAND": "YOUR HAND",

    /* PURCHASE */
    "BUY A GAME FROM A DEALER?": "BUY A GAME FROM A DEALER?",
    "YES": "YES",
    "NO": "NO",

    /* Result */
    "DEALER WINS": "DEALER WINS",
    "DRAW": "DRAW",
    "YOU WIN": "YOU WIN",

    /* Rules */
    'Rules': 'Rules',
    'How to play': 'How to play',

    /* Menu */
    'Menu': 'Menu',

    /* Buttons */
    "+1 CARD": "+1 CARD",
    "START": "START",
    "OPEN 3 CARD": "OPEN 3 CARD"

}

export default data